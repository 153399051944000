<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/companies">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 page-loader-div">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row content-div d-none2">
      <div class="col-md-3  text-center">
        <div class="card">
          <div class="card-body p-0 pt-4">
            <div class="row">
              <div class="col-md-12">
                <img v-if="company.logo_url == null" src="../../../../../assets/images/avatar.png" style="width:150px;">
                <img v-else :src="this.$appUrl + '/images/' + logo" style="width:150px;">
              </div>
              <div class="col-md-12 mt-3">
                <h5>{{ company.company_name }}</h5>
              </div>
              <div class="col-md-12 mt-3 menu-info">
                <a class="tab-link active" href="#" data-id="#company-info">
                  <i class="dripicons-user"></i>
                  معلومات الشركة
                </a>
                <a class="tab-link" href="#" data-id="#admin-info">
                  <i class="mdi mdi-office-building"></i>
                  المشرفين
                  <small class="float-end">{{ admins_count }}</small>
                </a>
                <a class="tab-link" href="#" data-id="#employee-info">
                  <i class="mdi mdi-seat-passenger"></i>
                  الموظفين
                  <small class="float-end">{{ employees_count }}</small>
                </a>
                <a class="tab-link" href="#" data-id="#bookings-info">
                  <i class="mdi mdi-ticket-confirmation-outline"></i>
                  الحجوزات
                  <small class="float-end">0</small>
                </a>
                <a class="tab-link" href="#" data-id="#transactions-info">
                  <i class="mdi mdi-credit-card-multiple-outline"></i>
                  المعاملات المالية
                  <small class="float-end">0</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class=" tab-content" id="company-info">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <h6 class="text-primary">
                    معلومات الشركة
                  </h6>
                </div>
                <div class="col-md-12">
                  <h6>
                    المعلومات الاساسية
                  </h6>
                </div>
                <div class="col-md-4">
                  <p class="m-0">اسم الشركة</p>
                  <p>{{ company.company_name }}</p>
                </div>
                <div class="col-md-4">
                  <p class="m-0">البريد الالكتروني</p>
                  <p class="text-primary">{{ company.email }}</p>
                </div>
                <div class="col-md-4">
                  <p class="m-0">رقم الجوال</p>
                  <p>{{ company.phone_country_code }}{{ company.phone }}</p>
                </div>
                <div class="col-md-12">
                  <hr>
                  <h6>
                    المعلومات الإضافية
                  </h6>
                </div>
                <div class="col-md-4">
                  <p class="m-0">معرف الشركة</p>
                  <p>{{ company.id }}</p>
                </div>
                <div class="col-md-4">
                  <p class="m-0">تاريخ الإنضمام</p>
                  <p>
                    {{ new Date(company.created_at).toLocaleDateString('en-US',
                      { day: '2-digit' }) }} /
                    {{ new
                      Date(company.created_at).toLocaleDateString('en-US', {
                        month: '2-digit'
                      }) }} /
                    {{ new Date(company.created_at).getFullYear() }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p class="m-0">الرقم الضريبي</p>
                  <p>{{ company.vat_number ?? 'N/A' }}</p>
                </div>
                <div class="col-md-4">
                  <p class="m-0">عنوان الشركة</p>
                  <p>{{ company.address ?? 'N/A' }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <h6 class="text-primary">
                    حالة الشركة
                  </h6>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="charts-card m0 mt-3">
                    <div class="row align-items-center mb-3">
                      <div class="col-md-4 mb-3">
                        <h6 class="mb-0 title">وضع التجربة</h6>
                      </div>
                      <div class="col-md-3 text-right">
                        <md-switch class="md-primary" v-model="company.is_test_mode" :value="1"
                          @change="test_mode_change"></md-switch>
                      </div>
                    </div>
                    <div class="row align-items-center mb-3">
                      <div class="col-md-4 mb-3">
                        <h6 class="mb-0 title">حالة الحساب</h6>
                      </div>
                      <div class="col-md-3 text-right">
                        <md-switch class="md-primary" v-model="company.status" :value="1"
                          @change="ChangeCompanyStatus(company.status, company.id)"></md-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="admin-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-6 col-6">
                <h6 class="text-primary">
                  المشرفين
                </h6>
              </div>
              <div class="col-md-6 col-6"></div>
              <div class="col-md-12">
                <md-table v-model="admins" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="المعرف" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="الاسم الكامل">
                      {{ item.employee?.first_name }} {{ item.employee?.last_name }}
                    </md-table-cell>
                    <md-table-cell md-label="رقم الجوال">
                      {{ item.phone_country_code }}{{ item.phone }}
                    </md-table-cell>
                    <md-table-cell md-label="الصلاحية">
                      {{ $t(item.dash_access) }}
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="admins_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>
            </div>
            <div v-if="admins_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="employee-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-6 col-6">
                <h6 class="text-primary">
                  الموظفين
                </h6>
              </div>
              <div class="col-md-6 col-6">
               
              </div>
              <div class="col-md-12">
                <md-table v-model="employees" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="المعرف" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="الاسم" style="direction: ltr;">
                      {{ item.first_name }} {{ item.last_name }}
                    </md-table-cell>
                    <md-table-cell md-label="رقم الجوال">
                      {{ item.phone_country_code }}{{ item.phone }}
                    </md-table-cell>
                    <md-table-cell md-label="البريد الالكتروني">
                      {{ item.email }}
                    </md-table-cell>
                    <md-table-cell md-label="تاريخ الإنضمام">
                      <span v-if="employees_count != 0">
                        {{ new Date(item.created_at).toLocaleDateString('en-US',
                          { day: '2-digit' }) }} /
                        {{ new
                          Date(item.created_at).toLocaleDateString('en-US', {
                            month: '2-digit'
                          }) }} /
                        {{ new Date(item.created_at).getFullYear() }}
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="employees_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <!-- <md-menu-item :href="'/users/individuals/details/' + encodeId(item.id)">
                            عرض
                          </md-menu-item>
                          <md-menu-item :href="'/users/individuals/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteUser(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item> -->
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="employees_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>

            </div>
            <div v-if="employees_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="bookings-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-6 col-6">
                <h6 class="text-primary">
                  الحجوزات
                </h6>
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-12">
                <md-table v-model="bookings" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="رقم الحجز" md-numeric>
                      {{ item.booking_reference }}
                    </md-table-cell>
                    <md-table-cell md-label="المسار" style="direction: ltr;">
                      {{ item.from }}-{{ item.to }}
                    </md-table-cell>
                    <md-table-cell md-label="الذهاب">
                      {{ item.departure_date }}
                    </md-table-cell>
                    <md-table-cell md-label="العودة">
                      {{ item.return_date }}
                    </md-table-cell>
                    <md-table-cell md-label="الحالة">
                      <span v-if="bookings_count != 0" v-bind:class="item.status"> {{
                        $t(item.status) }}</span>
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="bookings_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <!-- <md-menu-item :href="'/users/individuals/details/' + encodeId(item.id)">
                            عرض
                          </md-menu-item>
                          <md-menu-item :href="'/users/individuals/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteUser(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item> -->
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="bookings_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>

            </div>
            <div v-if="bookings_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="transactions-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-6 col-6">
                <h6 class="text-primary m-0">
                  المعاملات المالية
                </h6>
              </div>
              <div class="col-md-6 col-6">

              </div>
              <div class="col-md-12">
                <md-table v-model="transactions" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="المعرف" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="النوع" style="direction: ltr;">
                    </md-table-cell>
                    <md-table-cell md-label="الوصف">
                    </md-table-cell>
                    <md-table-cell md-label="التاريخ والوقت">
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="transactions_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <!-- <md-menu-item :href="'/users/individuals/details/' + encodeId(item.id)">
                            عرض
                          </md-menu-item>
                          <md-menu-item :href="'/users/individuals/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteUser(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item> -->
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="transactions_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>

            </div>
            <div v-if="transactions_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import $ from 'jquery';
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    title: "company details",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "company details",
      employees_count: 0,
      transactions_count: 0,
      bookings_count: 0,
      admins_count: 0,
      transactions: [{}, {}, {}, {}, {}],
      bookings: [{}, {}, {}, {}, {}],
      employees: [{}, {}, {}, {}, {}],
      currentPage: 1,
      company: [],
      admins: [{}, {}, {}, {}, {}],
      rows: null,
      perPage: 10,
    };
  },
  mounted() {
    this.jquery();
    $('.page-loader-div').show();
    var url = `${this.$appUrl}/api/admin/companies/show/${this.$route.params.id}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.company = response.data.company;
      this.employees = response.data.company.employees;
      this.employees_count = response.data.company.employees.length;
      this.bookings_count = response.data.company.bookings.length;
      this.admins_count = response.data.company.admins.length;
      if (this.employees_count > 0) {
        this.employees = response.data.company.employees;
      }
      if (this.bookings_count > 0) {
        this.bookings = response.data.company.bookings;
      }
      if (this.admins_count > 0) {
        this.admins = response.data.company.admins;
      }
      $('.content-div').removeClass('d-none2');
      $('.page-loader-div').hide();
    });
  },
  methods: {
    encodeId(id) {
      return btoa(id * 987654321);
    },
    test_mode_change(val) {
      val = Number(val);
      $('.pre-loader').show();
      var url = `${this.$appUrl}/api/admin/companies/update/${this.$route.params.id}?lang=${this.$i18n.locale}`;
      const headers = {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        "My-Custom-Header": "foobar"
      };
      let form = new FormData();
      form.append('is_test_mode', val);
      form.append('lang', this.$i18n.locale);
      this.axios.post(url, form, { headers })
        .then(response => {
          if (response.data.status == 1) {
            $('.pre-loader').hide();
            this.$toast.open({
              message: response.data.message,
              type: 'success',
              position: 'top-right',
              duration: 2500
            });
          } else {
            $('.pre-loader').hide();
            this.$toast.open({
              message: response.data.message,
              type: 'error',
              position: 'top-right',
              duration: 2500
            });
          }
        });
    },
    ChangeCompanyStatus(val, id) {
      $('.pre-loader').show();
      var url = `${this.$appUrl}/api/admin/companies/change-status/${this.encodeId(id)}/${val == 1 ? 1 : 0}?lang=${this.$i18n.locale}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        $('.pre-loader').hide();
        this.$toast.open({
          message: response.data.message,
          type: 'success',
          position: 'top-right',
          duration: 2500
        });
      });
    },
    handlePagination() {
      var url = `${this.$appUrl}/api/admin/users?page=${this.currentPage}&perPage=${this.perPage}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        this.tableData = response.data.users.data;
        this.rows = response.data.users.total;
      });
    },
    jquery() {
      $(document).on('click', '.menu-info .tab-link', function () {
        var id = $(this).data("id");
        $(document).find('.menu-info .tab-link').removeClass('active');
        $(this).addClass('active');
        $(document).find('.tab-content').hide();
        $(id).show();
      });
    }
  }
};
</script>
<style lang="scss">
.md-table.md-theme-default .md-table-row td {
  text-align: center;
}

.md-table.md-theme-default .md-table-head {
  text-align: center;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.table-responsive:has(.page-loader-parent) {
  min-height: 350px;
}

.md-icon.md-theme-default.md-icon-image svg {
  display: none;
}

.card {
  .md-field>.md-icon:after {
    background-color: rgb(0 0 0 / 0%);
  }
}

.menu-info {
  text-align: initial;

  a {
    color: #000 !important;
    width: 100%;
    display: block;
    border-top: 1px solid #ededed;
    padding: 15px 20px;
    font-size: 16px;

    &.active {
      background-color: #f7f7f7;
    }

    i {
      display: inline-block;
      min-width: 1.75rem;
      padding-bottom: 0.125em;
      line-height: 1.40625rem;
      vertical-align: middle;
      color: #00a99d;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      font-size: 18px;
    }
  }
}
</style>